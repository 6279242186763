$(function() {

    /*
    * Interface
     */
    $( "#menu-mobile" ).on( "click", function(event) {
        $('#menu').toggleClass('is-active');
        event.preventDefault();

        $('html').click(function(event) {
            $target = $(event.target);
            if ($target.parents('#menu-mobile').length == 0 && $target.parents('#menu').length == 0) {
                $('#menu').removeClass('is-active');
                $(this).unbind(event);
            }

        })


    });

    /*
     * Open comment form
     */
    if ($('#comment-form-message').length) {
        $('#comment-form-plus').addClass('is-hidden');
        $("#comment-form-message").on( "click", function() {
            $(this).css('height', '150px');
            $('#comment-form-plus').removeClass('is-hidden');
        });
    }

    /*
     * Accordion
     */
    if ($('.accordion').length) {
        $accordionItems = $('.accordion-item');
        $accordionItems.addClass('isClose');
        $accordionItems.on( "click", function() {
            var $this = $(this);
            if ($this.hasClass('isClose')) {
                $this.parents('.accordion').find('.accordion-item').addClass('isClose');
                $('html, body').animate({
                    scrollTop: $this.offset().top - 60
                }, 500, function() {
                    $this.removeClass('isClose');
                });
            }
        });
    }

    /*
     * QCM
     */
    if ($('.qcm').length) {
        $(".qcm").on( "click", '.qcm-reponse', function() {
            var $this = $(this);
            if ($this.hasClass('qcm-reponse-ok') || $this.hasClass('qcm-reponse-ko')) {
                return false;
            }
        });
        $(".qcm").on( "mouseenter", '.qcm-reponse', function() {
            var $this = $(this);
            if ($this.hasClass('isChecked') && $this.hasClass('qcm-reponse-ko')) {
                $this.removeClass('isChecked');
                $this.find('input').prop( "checked", false );
                window.setTimeout(function () {
                    $this.addClass('isChecked');
                    $this.find('input').prop( "checked", true );
                }, 2000);
            }
            if (!$this.hasClass('isChecked') && $this.hasClass('qcm-reponse-ok')) {
                $this.addClass('isChecked');
                $this.find('input').prop( "checked", true );
                window.setTimeout(function () {
                    $this.removeClass('isChecked');
                    $this.find('input').prop( "checked", false );
                }, 2000);
            }
        });
    }

    /*
     * See string in password input
     */
    if ($('.form-password').length) {
        $('.form-password')
            .wrap('<div class="form-password-wrapper">')
            .before('<span class="form-password-command">afficher</span>')
            .on("keyup", function () {
                if ($(this).val() != '') {
                    $(this).parent().find('.form-password-command').show();
                } else {
                    $(this).parent().find('.form-password-command').hide();
                }
            });
        $(".form-password-command").hide().on("click", function () {
            var $input = $(this).parent().find('input');
            if ($input.attr('type') == 'text') {
                $input.attr('type', 'password');
                $(this).text('afficher');
            } else {
                $input.attr('type', 'text');
                $(this).text('cacher');
            }
        })
    }

    /*
     * Google Maps
     */
    if ($('#carte_annonce').length) {
        $('#carte_annonce').mapGoogle({
            datas: annoncesMarkersDatas
        });
    }
    if ($('#carte_annonce_detail').length) {
        $('#carte_annonce_detail').mapGoogle({
            map: {
                zoom: 7,
                center: annoncesMarkerDatas[0].position
            },
            datas: annoncesMarkerDatas
        });
    }
    if ($('#carte_annonce_edition').length) {
        var myOptions = {
            map: {},
            placeMarker: true,
            placeMarkerCallback: function(location) {
                $('#gps').val(location.lat() + ',' + location.lng());
            }
        };
        $gps = $('#gps');
        if ($gps.val() != '') {
            var gps = $gps.val().split(',');
            var position = { lat: parseInt(gps[0]), lng: parseInt(gps[1]) };
            myOptions.map.center = position;
            myOptions.datas = {'position': position};
        }
        $('#carte_annonce_edition').mapGoogle(myOptions);

        $('#geolocalisation').geolocalisation({
            success: function(position) {
                $('#gps').val(position.coords.latitude + ',' + position.coords.longitude);
                $('#carte_annonce_edition').mapGoogle('deleteMarkers').mapGoogle('addMarker', {
                    "position": {
                        "lat": position.coords.latitude,
                        "lng": position.coords.longitude
                    }
                }).mapGoogle('autoCenterMap');
            },
            error: function(message) {
                $('#gps').val(message);
            }
        }).css('text-decoration', 'underline').css('cursor', 'pointer');
    }


    /*
     * Scroll
     */

    // Bouton Top
    var timer;
    var $footerTop  = $("#footer-top");
    var windowHeight = $(window).height();

    $(window).scroll(function() {
        if(timer) {
            window.clearTimeout(timer);
        }
        timer = window.setTimeout(function() {
            var scroll = $(window).scrollTop();
            var visible = $footerTop.is(":visible");
            if (visible && scroll < windowHeight) {
                $footerTop.hide(150);
            }
            if (!visible && scroll > windowHeight - 200) {
                $footerTop.show(150);
            }
        }, 100);
    });

    // Scroll to anchor animate
    $('a[href^="#"]').on('click', function(event) {
        var target = $($(this).attr('href'));
        if( target.length ) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 1000);
        }
    });

    /*
     * Revert obfuscate Email
     */
    if ($('.email-obfuscate').length) {
        (function() {
            var $email = $('.email-obfuscate');
            var action = ":otliam".split("").reverse().join("");
            var href = $email.data('rot_mail').replace(/[a-zA-Z]/g, function(c){return String.fromCharCode((c<="Z"?90:122)>=(c=c.charCodeAt(0)+13)?c:c-26);});
            href = href.substr(0, $email.data('at_index')) + String.fromCharCode(4*2*2*4) + href.substr($email.data('at_index'));
            $email.html('<a href="' + action + href + '"><span>' + href + '</span></a>');
        })();
    }
});
