;(function ( $, window, undefined ) {

    var pluginName = 'geolocalisation',
        defaults = {
            success: function() {},
            error: function() {}
        };

    // Plugin constructor
    function Plugin( element, options ) {
        this.element = element;
        this.$element = $(this.element);
        this.options = $.extend( {}, defaults, options);

        this.init();

        return this;
    }

    Plugin.prototype = {

        init: function() {
            var self = this;

            this.$element.on( "click", function() {
                if(navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function(position) {
                        self.options.success(position, self);
                    }, function(error) {
                        self.erreurPosition(error, self)
                    });
                } else {
                    self.returnErreur('Votre navigateur ne supporte pas la géolocalisation.', null);
                }
            });
        },

        erreurPosition: function (error, self) {

            var info = "Erreur lors de la géolocalisation : ";
            switch(error.code) {
                case error.TIMEOUT:
                    info += "Timeout !";
                    break;
                case error.PERMISSION_DENIED:
                    info += "Vous n’avez pas donné la permission";
                    break;
                case error.POSITION_UNAVAILABLE:
                    info += "La position n’a pu être déterminée";
                    break;
                case error.UNKNOWN_ERROR:
                    info += "Erreur inconnue";
                    break;
                default :
                    info += error.message;
            }
            self.returnErreur(info, error, self);
        },

        returnErreur: function (info, error, self) {
            self.options.error(info, error);
        }
    };


    // Adding Plugin to the jQuery.fn object
    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'plugin_' + pluginName)) {
                $.data(this, 'plugin_' + pluginName, new Plugin( this, options ));
            }
        });
    };

}(jQuery, window));