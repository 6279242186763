/****
 * @name    Jquery Plugin Google maps
 * @copyright   B1nj
 * @license License:X11 (MIT)
 *
 * Example :
 * $('#mapId').mapGoogle({
 *    map: {
 *        zoom: 7,
 *    },
 *    datas: [
 *    {
 *        "value": "Title",
 *        "content": 'Text infoWindow',
 *        "position": {
 *            lat: 47.868,
 *            lng: 4.083
 *        }
 *    }]
 * })
 * .mapGoogle('autoCenterMap');
 *
 */

;(function ( $, window, undefined ) {

    var pluginName = 'mapGoogle',
        defaults = {
            map: {
                zoom: 5,
                scrollwheel: false,
                mapTypeId: null,
                center: {
                    lat: 46.225453,
                    lng: 2.219238
                }
            },
            datas: null,
            placeMarker: false,
            placeMarkerCallback: function() {}
        };

    // Plugin constructor
    function Plugin( element, options ) {

        if (typeof google === 'undefined') {
            return this;
        }

        this.element = element;
        //this.$element = $(element);
        defaults.map.mapTypeId = google.maps.MapTypeId.ROADMAP;
        var map = $.extend( {}, defaults.map, options.map );
        this.options = $.extend( {}, defaults, options);
        this.options.map = map;
        this.markers = [];
        this.map = null;
        this.infoWindow = null;
        this.bounds = null;

        this._init();
    }

    Plugin.prototype = {

        _init: function() {
            var self = this;

            self.map = new google.maps.Map(self.element, self.options.map);
            self.infoWindow = new google.maps.InfoWindow();
            self.bounds = new google.maps.LatLngBounds();

            if (self.options.datas) {
                if (Array.isArray(self.options.datas)) {
                    self.addMarkers(self.options.datas);
                } else {
                    self.addMarker(self.options.datas);
                }
            }

            if (self.options.placeMarker) {
                google.maps.event.addListener(self.map, 'click', function(event) {
                    self.placeMarker(event.latLng);
                });
            }
        },

        addMarkers: function (datas) {
            var self = this;

            datas.forEach(function(data) {
                self.addMarker(data);
            });
        },

        addMarker: function (data) {
            var self = this;

            var marker = new google.maps.Marker({
                position: data.position,
                map: this.map,
                title : data.value
            });

            // InfoWindow
            if (data.content) {
                (function (marker, data) {
                    google.maps.event.addListener(marker, "click", function (e) {
                        self.infoWindow.setContent(data.content);
                        self.infoWindow.open(self.map, marker);
                    });
                })(marker, data);
            }

            this.bounds.extend(marker.position);
            this.markers.push(marker);
        },

        placeMarker: function (location) {
            if(this.markers[0]){
                this.markers[0].setPosition(location);
            }else{
                data = {"position": location};
                this.addMarker(data);
            }
            this.options.placeMarkerCallback(location);
        },

        deleteMarkers: function () {
            this.clearMarkers();
            this.markers = [];
        },

        clearMarkers: function () {
            this.setMapOnAll(true);
        },

        showMarkers: function () {
            this.setMapOnAll();
        },

        setMapOnAll: function (clear) {
            var map = clear ? null : this.map;
            this.markers.forEach(function(marker) {
                marker.setMap(map);
            });
        },

        autoCenterMap: function () {
            var self = this;

            this.map.fitBounds(this.bounds);
            var listener = google.maps.event.addListener(this.map, "idle", function() {
                if (self.map.getZoom() > 13) self.map.setZoom(13);
                google.maps.event.removeListener(listener);
            });
        },

        // Animate the marker
        toggleBounce: function (i) {
            if (this.markers[i].getAnimation() != null) {
                this.markers[i].setAnimation(null);
            } else {
                this.markers[i].setAnimation(google.maps.Animation.BOUNCE);
            }
        }
    }







    // Adding Plugin to the jQuery.fn object
    // https://github.com/jquery-boilerplate/jquery-boilerplate/wiki/Extending-jQuery-Boilerplate
    $.fn[pluginName] = function ( options ) {
        var args = arguments;
        if (options === undefined || typeof options === 'object') {
            return this.each(function () {
                if (!$.data(this, 'plugin_' + pluginName)) {
                    $.data(this, 'plugin_' + pluginName, new Plugin( this, options ));
                }
            });
        } else if (typeof options === 'string' && options[0] !== '_' && options !== 'init') {
            var returns;

            this.each(function () {
                var instance = $.data(this, 'plugin_' + pluginName);
                if (instance instanceof Plugin && typeof instance[options] === 'function') {
                    returns = instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
                }
                if (options === 'destroy') {
                    $.data(this, 'plugin_' + pluginName, null);
                }
            });
            return returns !== undefined ? returns : this;
        }
    };

}(jQuery, window));